import { loginUser } from "~/graphql/mutation/loginUser/loginUser";
import { useApolloToken } from "~/utils/cookie";
import { useUserStore } from "~/stores/UserStore";

interface Args {
  email: string;
  password: string;
}

export const login = async ({ email, password }: Args) => {
  const store = useUserStore();

  const { data } = await loginUser({ email, password });

  if (data.value) {
    store.user = data.value.user;

    // Put token in storage.
    useApolloToken().value = data.value.token;
  }
};