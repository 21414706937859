import { FetchPolicy, OperationVariables } from "@apollo/client";
import { notify } from "@kyvg/vue3-notification";
// @ts-ignore
import { OptionsParameter } from "@vue/apollo-composable/dist/useQuery";
import { DocumentNode } from "graphql/language";
import { useProdApiCookie } from "~/utils/cookie";

export interface QueryOptions<TResult = any> extends OptionsParameter<TResult, Record<string, never>> {
  prefetch?: boolean;
  fetchPolicy?: FetchPolicy;
  resolve?: boolean;
  hideError?: boolean;
}

interface MutateArgs<_, TVariables> {
  mutation: DocumentNode;
  variables: TVariables;
}

export const useTeamfightMutation = async <TResult, TVariables extends OperationVariables>(args: MutateArgs<TResult, TVariables>) => {
  const { mutation, variables } = args;

  const mutationQuery = useMutation<TResult>(mutation, { variables });

  mutationQuery.onError((e) => {
    notify({
      title: e.name,
      text: e.message,
      group: 'error',
      type: "error",
      duration: process.env.NUXT_PUBLIC_NODE_ENV !== 'production' ? -1 : 3000
    });

    console.error(e);
  });

  const result = await mutationQuery.mutate();

  return { ...mutationQuery, result };
};

interface QueryArgs<TResult, TVariables extends OperationVariables = {}> {
  query: DocumentNode;
  variables?: TVariables;
  options?: QueryOptions<TResult>;
}

export const useTeamfightQuery = async <TResult, TVariables extends OperationVariables = {}>(args: QueryArgs<TResult, TVariables>) => {
  const { query, variables = {}, options = {} } = args;

  const clientId = process.env.NUXT_PUBLIC_NODE_ENV === 'production' ? 'default' : useProdApiCookie().value ? 'prod' : 'default';

  const queryReturn = useQuery<TResult>(query, variables, { clientId, ...options as OptionsParameter });

  queryReturn.onError((e) => {
    if (!options.hideError) {
      notify({
        title: e.name,
        text: e.message,
        group: 'error',
        type: "error",
        duration: process.env.NUXT_PUBLIC_NODE_ENV !== 'production' ? -1 : 3000
      });
    }

    console.error(`Apollo Error: ${e.name} - ${e.message}.`);
  });

  if (options.resolve) {
    await new Promise((resolve, reject) => {
      queryReturn.onResult(r => {
        if (r.data) {
          resolve(r);
        }
      });

      queryReturn.onError(e => {
        if (!options.hideError) reject(e);
        else resolve(e);
      });
    });
  }

  return queryReturn;
};