export const useScreen = () => {
  const width = ref(import.meta.client ? window.innerWidth : 0);
  const height = ref(import.meta.client ? window.innerHeight : 0);
  const breakpoints = useBreakpoints({
    xs: 0,
    sm: 576,
    md: 768,
    lg: 960,
    xl: 1280,
    xxl: 1536,
  });
  // Device from user-agent
  const { isMobile, isMobileOrTablet, isDesktopOrTablet, isDesktop } =
    useDevice();

  if (import.meta.client) {
    window.addEventListener('resize', () => {
      width.value = window.innerWidth;
    });

    window.addEventListener('resize', () => {
      height.value = window.innerHeight;
    });
  }

  return {
    width,
    height,
    breakpoints,
    xs: computed(() =>
      import.meta.server ? isMobile : breakpoints.greaterOrEqual('xs').value
    ),
    sm: computed(() =>
      import.meta.server
        ? isMobileOrTablet
        : breakpoints.greaterOrEqual('sm').value
    ),
    md: computed(() =>
      import.meta.server
        ? isDesktopOrTablet
        : breakpoints.greaterOrEqual('md').value
    ),
    lg: computed(() =>
      import.meta.server
        ? isDesktopOrTablet
        : breakpoints.greaterOrEqual('lg').value
    ),
    xl: computed(() =>
      import.meta.server ? isDesktop : breakpoints.greaterOrEqual('xl').value
    ),
    xxl: computed(() =>
      import.meta.server ? isDesktop : breakpoints.greaterOrEqual('xxl').value
    ),
    isDesktop: computed(() => width.value >= 1280),
  };
};
